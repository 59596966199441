import React, {FunctionComponent} from "react";
import {Nav, NavContainer, NavLink, NavMenu, NavMenuItem, NavWrapper, SearchContainer} from "./style";
import {MenuItem} from "../../utils/models";
import Logo from "../logo";
import Search from "../search"
const searchIndices = [{ name: `smile-0yen.dev`, title: `smile-0yen.dev` }]

interface NavigationProps {
  title: string;
  menu: MenuItem[];
  dark?: boolean;
}

const Navigation: FunctionComponent<NavigationProps> = ({title, menu, dark = false}) => (
  <NavContainer dark={dark}>
    <Nav>
      <Logo title={title}/>
      <NavWrapper>
        <NavMenu mobile={true}>
          {menu.map((item, index) => (
            <NavMenuItem key={index}>
              <NavLink to={item.path} key={index}>{item.name}</NavLink>
            </NavMenuItem>
          ))}
        </NavMenu>
        <SearchContainer>
          <NavMenu>
            <Search indices={searchIndices} />
          </NavMenu>
        </SearchContainer>
      </NavWrapper>
    </Nav>
  </NavContainer>
);

export default Navigation;
